import React, { useState } from 'react';

import './index.scss';

import { Text, Image, Link } from 'components/atoms';

import { useWindowSize } from 'utils/windowSizeHook';

const SelectedClientsItemNew = ({
  clientInfo,
  clientImageSrc,
  clientImageAlt,
  clientImageDimension,
  className,
  clientLink,
  onClick,
  private_access,
}) => {
  const windowWidth = useWindowSize();
  const [imageRatio, setImageRatio] = useState(0.78);

  if (windowWidth) {
    let newImageRatio = windowWidth > 1024 ? 0.78 : 0.67;
    if (imageRatio !== newImageRatio) {
      setImageRatio(newImageRatio);
    }
  }

  const { width, height } = clientImageDimension;

  return (
    <div className={className}>
      <div className={`${className}__image`}>
        <div
          className={`${className}__image-wrapper`}
          style={{
            maxWidth: width * imageRatio + 'px',
            maxHeight: height * imageRatio + 'px',
          }}
        >
          {clientImageSrc && (
            <Image
              src={clientImageSrc}
              alt={clientImageAlt}
              width={width}
              height={height}
            />
          )}
        </div>
      </div>
      <Text className={`${className}__text`}>
        {clientInfo ? clientInfo : ''}
      </Text>
      {private_access ? (
        <div
          className={`${className}__link`}
          onClick={onClick}
          onKeyDown={onClick}
          role="button"
          tabIndex="0"
          aria-label='private access'
        />
      ) : (
        clientLink && (
          <Link
            href={clientLink}
            className={`${className}__link`}
            isRouter={false}
          >
            <Text>{clientImageAlt ? clientImageAlt : ''}</Text>
          </Link>
        )
      )}
    </div>
  );
};

SelectedClientsItemNew.defaultProps = {
  className: '',
  clientImageDimension: { width: 200, height: 100 },
};

export default SelectedClientsItemNew;
